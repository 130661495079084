export const homeObjOne = {
    id: 'about', 
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'About Me',
    headline: 'Full Stack Web Developer',
    description: 'Hello! My name is Blake Davis and I live in the southwestern region of Ohio. I have 15+ years of learning and experience in the electronics and tech world. I recently graduated Lambda School for Web Development in 2021. I also worked as a Senior Advisor with Apple through the AppleCare division. Previously, I worked as an F15 Avionics Technician with the U.S. Air Force. ',
    buttonLabel: 'Download Resume',
    imgStart: false,
    img: require('../../images/svg-1.svg').default, 
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false,
};

export const homeObjTwo = {
    id: 'discover', 
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Projects',
    headline: 'Check Out My Work',
    description: 'Here is a link to access some of the web development projects I have worked on during and after Lambda School.',
    buttonLabel: 'View',
    imgStart: false,
    img: require('../../images/svg-5.svg').default, 
    alt: 'Car2',
    dark: false,
    primary: false,
    darkText: true,
};

export const homeObjThree = {
    id: 'signup', 
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Contact',
    headline: 'Get In Touch',
    description: 'Here is a link to get in contact with me.',
    buttonLabel: 'Message',
    imgStart: false,
    img: require('../../images/svg-2.svg').default, 
    alt: 'Car',
    dark: false,
    primary: false,
    darkText: true,
};